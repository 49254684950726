// MarqueeComponent.js
import React from 'react';
import './Marque.css'; // Import the CSS file
import logo3 from './../../assets/gul.png'
import logo2 from './../../assets/East.png'
import logo1 from './../../assets/sage.jpg'
import logo4 from './../../assets/logo4.jpg'

import logo6 from './../../assets/logo6.jpg'
import logo7 from './../../assets/logo7.jpg'
import logo8 from './../../assets/zuri.png'
import logo9 from './../../assets/gg.png'
import logo10 from './../../assets/logo10.jpg'
import logo11 from './../../assets/logo11.jpg'
import logo12 from './../../assets/logo12.jpg'
import logo13 from './../../assets/logo13.jpg'
import logo14 from './../../assets/RC.png'
import logo15 from './../../assets/logo15.jpg'
import logo16 from './../../assets/logo16.jpg'
import logo17 from './../../assets/AC.png'
import logo18 from './../../assets/ARA1.png'
import logo19 from './../../assets/logo19.jpg'


// Example logos array
const logos = [
  logo1,
  logo2,
  logo3,
  logo4,logo6,logo7,logo8,logo9,logo10,logo11,logo12,logo13,logo14,logo15,logo16,logo17,logo18,
  // Add more logos as needed
];

const continuousLogos = [...logos, ...logos, ...logos,...logos]; 

const MarqueeComponent = () => {
  return (
    <div className="container">
      <h1 className="title">Our Clients</h1>
      <div className="marquee-container">
        <div className="marquee left">
          {continuousLogos.map((logo, index) => (
            <img key={index} src={logo} alt={`Logo ${index}`} className="logo" />
          ))}
  
        </div>
    {   /* <div className="marquee right">
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt={`Logo ${index}`} className="logo" />
          ))}
        </div>*/}
      </div>
    </div>
  );
};

export default MarqueeComponent;
