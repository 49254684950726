import React from 'react';
import './PrivacyPolicy.css';
import Footer from './Footer';
import Navbar from './Navbar';

const PrivacyPolicy = () => {
    return (
        <>
        <Navbar/>
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p><strong>Last Updated: 19/09/2024</strong></p>

            <p>At G For Growth, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, store, and protect your personal information when you interact with our website, services, or any communications from us. By using our website (<a href="https://www.gforgrowth.com">www.gforgrowth.com</a>) or services, you agree to the terms outlined in this policy.</p>

            <h2>1. Information We Collect</h2>
            <h3>a) Personal Information</h3>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Company name</li>
                <li>Address</li>
                <li>Payment information (if applicable for billing purposes)</li>
                <li>Any other details you voluntarily provide in forms or during communications.</li>
            </ul>
            <h3>b) Non-Personal Information</h3>
            <ul>
                <li>Browsing behavior (pages visited, time spent on pages)</li>
                <li>Device information (IP address, browser type, device type)</li>
                <li>Cookies and similar tracking technologies (for analytics, improving user experience, and targeted ads)</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <ul>
                <li>To provide our services: Responding to inquiries, delivering projects, and providing consultations.</li>
                <li>For communication: Sending updates, marketing communications, newsletters, and promotional content (with your consent).</li>
                <li>To improve our services and website: Analyzing usage patterns to enhance our website and services.</li>
                <li>For legal purposes: Complying with laws and regulations, and ensuring compliance with our terms and conditions.</li>
            </ul>

            <h2>3. How We Share Your Information</h2>
            <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following cases:</p>
            <ul>
                <li><strong>Service Providers:</strong> We may share your data with third-party service providers (e.g., email marketing platforms, analytics tools) to support our operations.</li>
                <li><strong>Legal Obligations:</strong> If required by law, we may disclose your information to comply with legal processes or governmental requests.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred to a new entity.</li>
            </ul>

            <h2>4. Cookies and Tracking Technologies</h2>
            <p>Our website uses cookies and similar tracking technologies to enhance your browsing experience and provide personalized content. Cookies help us understand your preferences, optimize our website performance, and offer tailored ads through platforms like Google or Facebook.</p>
            <p>You can control cookie preferences through your browser settings, but note that disabling cookies may impact your experience on our website.</p>

            <h2>5. Data Security</h2>
            <p>We implement robust security measures to protect your personal data from unauthorized access, loss, or alteration. While we strive to secure your information, no method of transmission over the internet is entirely secure. Therefore, we cannot guarantee its absolute security.</p>

            <h2>6. Your Rights</h2>
            <ul>
                <li><strong>Access:</strong> Request access to the information we hold about you.</li>
                <li><strong>Correction:</strong> Request corrections or updates to inaccurate or incomplete information.</li>
                <li><strong>Deletion:</strong> Request deletion of your personal information, subject to legal requirements.</li>
                <li><strong>Opt-out:</strong> Unsubscribe from marketing communications at any time by clicking the "unsubscribe" link in our emails.</li>
            </ul>
            <p>To exercise any of these rights, please contact us at connect@gforgrowth.com.</p>

            <h2>7. Third-Party Links</h2>
            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We recommend reviewing the privacy policies of any third-party websites you visit.</p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</p>
            <p>G For Growth<br />Email: connect@gforgrowth.com<br />Website: <a href="https://www.gforgrowth.com">www.gforgrowth.com</a></p>
        </div>
        <Footer/>
        </>
    );
};

export default PrivacyPolicy;
