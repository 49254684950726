import React from 'react';
import './AgencySection.css'; // Create a CSS file for styles
import agency from '../assets/digital-marketing.jpg'
import person from "../assets/pic2.jpg"
const AgencySection = () => {
    return (
        <section className="agency-section">
            <div className="agency-container">
                <div className="agency-image">
                    <img src={agency} alt="Agency Team" />
                </div>
                <div className="agency-content">
                    <h2>About Our Agency</h2>
                    <h1>We are working for your success</h1>
                    <p>
                    At G For Growth, a leading digital marketing agency based in Mumbai, your success drives everything we do. Whether through SEO strategies that boost search rankings, web development that enhances user experience, or social media marketing campaigns that engage target audiences, we tailor solutions to fit your unique needs. Our focus is on delivering measurable business growth, improving brand visibility, and helping you reach your goals through innovative, data-driven digital strategies.
                    </p>
                    <div className="agency-author">
                        {/* <img src={person} alt="Author" /> */}
                        <div>
                            <p>Sanjay Banerjee</p>
                            <span>The Boss</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AgencySection;
