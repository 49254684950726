import React from 'react';
import './Footer.css';
import { FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h3>G For Growth Agency</h3>
                <p>Mumbai, India</p>
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/gforgrowth" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                    <a href="https://www.instagram.com/gforgrowthmarketing/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                    {/* <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a> */}
                </div>
            </div>
            <div className="footer-section">
                <ul>
                <h4>COMPANY</h4>
                    <li><a href="/about">About</a></li>
                    {/* <li><a href="#team">Our team</a></li> */}
                    <li><a href="/contact">Contact us</a></li>
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                </ul>
            </div>
            {/* <div className="footer-section">
                <h4>SOCIAL</h4>
                <ul>
                    <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                    <li><a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                    <li><a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">YouTube</a></li>
                </ul>
            </div> */}

<div className="footer-section newsletter-section">
                <h4>NEWSLETTER</h4>
                <p>Stay updated with our latest news and insights.</p>
               <form className='newsletter-data'>     
               <div> <input type="text" placeholder="Name" className="newsletter-input" /></div>
               <div><input type="email" placeholder="Email" className="newsletter-input" /></div>
                <button className="newsletter-btn">Subscribe</button>
                </form>
            </div>
            <div className='copy'>
                Copyright ©  <a href='/'>G For Growth</a> Pvt.Ltd
            </div>
        </footer>
    );
};

export default Footer;
