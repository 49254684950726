import React, { useState } from 'react';
import './Aboutservices.css';
import webDevImg from './../assets/Frame1.png'; 
import graphicDesignImg from './../assets/Frame.png'; 
import digitalMarketingImg from './../assets/Frame2.png'; 
import seoImg from './../assets/Frame3.png'; 
import brandingImg from './../assets/Frame4.png'; 
import salesFunnelImg from './../assets/Frame5.png'; 

const Aboutservices = () => {
    // State to track the currently active tab
    const [activeTab, setActiveTab] = useState('Web Development');

    // Data for services (tabs and content)
    const services = [
        {
            title: 'Web Development',
            image: webDevImg,
            description: 'Professional web development services that design and build responsive, SEO-friendly websites for small businesses and enterprises. These custom web solutions enhance user engagement, improve website performance, and provide a seamless digital experience to drive business growth.'

        },
        {
            title: 'Graphic Design',
            image: graphicDesignImg,
            description: 'Graphic design services for businesses looking to enhance brand identity through visually compelling, custom-made graphics. These professional design solutions help communicate brand messaging effectively and leave a lasting impression on the target audience, boosting brand awareness.'
        },
        {
            title: 'Digital Marketing',
            image: digitalMarketingImg,
            description: 'Comprehensive digital marketing services to increase online visibility and attract targeted traffic. Digital strategies include SEO for eCommerce businesses, paid social media advertising, and content marketing to drive user engagement and achieve measurable business growth.'
        },
        {
            title: 'SEO Services',
            image: seoImg,
            description: 'SEO services tailored to improve search engine rankings for small businesses and startups. Advanced SEO techniques, including long-tail keyword optimization, high-quality link building, and on-page SEO audits, help drive organic traffic, increase brand visibility, and boost conversion rates.'
        },
        {
            title: 'Branding',
            image: brandingImg,
            description: 'Branding services designed to help businesses create a distinct brand identity that resonates with their target audience. From logo design to comprehensive brand strategy, this approach ensures that businesses stand out from competitors and foster long-term customer loyalty.'
        },
        {
            title: 'Sales Funnel',
            image: salesFunnelImg,
            description: 'Custom sales funnel creation services that guide potential customers through every stage of the buyers journey. This strategy nurtures leads, improves the customer experience, and increases sales conversions by addressing the needs of prospects from awareness to purchase.'
        }
    ];

    return (
        <section className="services">
            <div className="services-content">
                <h2 className="err">Professional Digital Services and Solutions</h2>

                {/* Tabs Section */}
                <div className="roww">
                <div className="tabs">
                    {services.map(service => (
                        <button 
                            key={service.title}
                            className={`tab-button ${activeTab === service.title ? 'active' : ''}`}
                            onClick={() => setActiveTab(service.title)}
                        >
                            {service.title}
                        </button>
                    ))}
                </div>

                {/* Content Section */}
                <div className="services-details">
                    {services
                        .filter(service => service.title === activeTab)
                        .map(service => (
                            <div key={service.title} className="services-card">
                                <img src={service.image} alt={service.title} className="services-icon" />
                                <h2 className='op'>{service.title}</h2>
                                <p className='po'>{service.description}</p>
                            </div>
                        ))}
                </div>
                </div>
            </div>
        </section>
    );
};

export default Aboutservices;
