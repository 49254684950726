import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../assets/logon.svg';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <section className="navbar-main">
            <div className="navbar-container">
                <Link to='/'><img src={logo} alt="Logo" /></Link>
                {!isMobile && (
                    <div className="navbar-right-content">
                        <Link to='/about'>About Us</Link>
   {                /*     <a href="/blog">Blog</a>*/}
                        <Link to='/contact'>Contact Page</Link>
                    </div>
                )}
                <div className="navbar-hamburger" onClick={toggleSidebar}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <span className="sidebar-close" onClick={closeSidebar}>&times;</span>
                <Link to='/about' onClick={closeSidebar}>About Us</Link>
  {/*              <a href="/blog" onClick={closeSidebar}>Blog</a>*/}
                <Link to='/contact' onClick={closeSidebar}>Contact Page</Link>
            </div>

            <div className={`sidebar-overlay ${sidebarOpen ? 'active' : ''}`} onClick={closeSidebar}></div>
        </section>
    );
};

export default Navbar;
