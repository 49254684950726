import React from 'react';
import './AgencyPage.css'; // CSS file for the page
import video from '../assets/cpt-video-layer-2.webp'
import { Link } from 'react-router-dom';
const AgencyPage = () => {
    return (
        <div className="agency-page">
            {/* Section 1: Why you should work with our agency */}
            <section className="why-agency">
            <div className="full">
                <div className="why-content">
                    <h2>Why you should work with our agency?</h2>
                    <Link to ='/contact'><button className="get-started-btn">Get started</button></Link>
                </div>
                <div className="features">
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Customer Satisfaction</h3>
                        <p>Ensuring the highest level of client satisfaction through tailored digital marketing solutions, including SEO, social media marketing, and web development services.</p>
                    </div>
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Experienced Team</h3>
                        <p>A team of digital marketing experts with years of experience in SEO optimization, paid advertising, and branding strategies</p>
                    </div>
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Modern Tools</h3>
                        <p>Utilizing the latest SEO tools, analytics platforms, and web development technologies for optimized performance and measurable growth</p>
                    </div>
                    <div className="feature-item">
                        <i className="icon-placeholder" />
                        <h3>Tailored Solutions
                        </h3>
                        <p>Offering customized digital marketing strategies to meet the specific needs of your business, driving targeted traffic and boosting conversion rates.
                        </p>
                    </div>
                </div>
                </div>
            </section>


        </div>
    );
};

export default AgencyPage;
